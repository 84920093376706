import { render, staticRenderFns } from "./form-builder.html?vue&type=template&id=5e4f017a&scoped=true&external"
import script from "./form-builder.js?vue&type=script&lang=js&external"
export * from "./form-builder.js?vue&type=script&lang=js&external"
import style0 from "./form-builder.scss?vue&type=style&index=0&id=5e4f017a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4f017a",
  null
  
)

export default component.exports